@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.container {
  background-color: rgba(255, 255, 255, 0.875);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  overflow: hidden;
}

.hideOverlay {
  background-color: transparent;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loader {
  font-size: 0.5rem;
  text-indent: -9999rem;
  border-top: 0.25rem solid rgba(0, 0, 0, 0.125);
  border-right: 0.25rem solid rgba(0, 0, 0, 0.125);
  border-bottom: 0.25rem solid rgba(0, 0, 0, 0.125);
  border-left: 0.25rem solid #5dc0de;
  transform: translateZ(0);
  animation: spin 0.75s infinite linear;
  margin: 0 auto 1rem;
}

.loader,
.loader::after {
  border-radius: 50%;
  z-index: 6;
  overflow: hidden;
  width: 4rem;
  height: 4rem;
}
