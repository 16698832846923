.container {
  margin: 0.25rem 0;
}

.bar {
  display: flex;
  align-items: center;
}

.bar :global(.btn) {
  flex-shrink: 0;
  padding-right: 0.5rem;
}

.bar :global(.btn) svg {
  display: inline-block;
  fill: currentColor;
  line-height: 1;
  stroke: currentColor;
  stroke-width: 0;
  vertical-align: sub;
}

.advanced {
  margin: 0.5rem;
}

.advanced :global(.radio-inline) {
  padding-top: 0;
}

.advanced label {
  font-size: 14px;
}

.removeGroup {
  margin-right: -0.5rem;
  margin-top: 8px;
}

.channelSelect {
  width: 80px;
  flex-shrink: 0;
}

.advancedFormGroup {
  display: flex;
  align-items: baseline;
  margin-right: -0.5rem;
}

.inlineFormGroup {
  padding-left: 16px;
  display: inline-block;
}

.advancedFormGroup :global(.help-block) {
  margin-bottom: 0px;
}

.labelText {
  display: inline-block;
}
