.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.text {
  margin-top: 1rem;
  height: 66px;
  width: 310px;
  color: #5d6f85;
  font-family: Lato;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}
