html,
body {
  height: 100%;
  overflow: hidden;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.popover-title {
  margin: 0;
  padding: 4px 14px;
  font-size: 14px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
  font-weight: bold;
}

.form-horizontal .control-label {
  padding-top: 7px;
  text-align: left;
}
.form-horizontal .form-group {
  margin-bottom: 0;
}

.navbar-right {
  margin-right: 0;
}
